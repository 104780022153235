import type {
  CatalogType, ICatalog, ICpArticle, IFilters,
} from '~/types';

export const catalogMSPaths = {
  catalog: '/catalog/catalog' as const,
  filters: '/catalog/filter' as const,
  articles: '/catalog/articles' as const,
};


export const getAFCatalog = (id: string, type: CatalogType, page: number) => {
  const options = {
    method: 'GET',
    params: {id, type, page},
    withoutAuthorization: true,
    useMS: true,
  };

  return $cpFetch<ICatalog>(catalogMSPaths.catalog, options);
};

export const getAFCatalogFilter = (id: string, type: string, filters: any, sort: string, page: number, pageSize = 100, url?: string) => {
  const options = {
    method: 'GET',
    params: {id, type, pageSize, page, sort, ...filters, ...(url ? {url} : {})},
    withoutAuthorization: true,
    useMS: true,
  };

  return $cpFetch<IFilters>(catalogMSPaths.filters, options);
};

export const getAFCatalogArticles = async (articlesIds: string[], bySku?: boolean) => {
  const options = {
    method: 'GET',
    params: {'articles[]': articlesIds, 'bySku': bySku},
    withoutAuthorization: true,
    useMS: true,
  };

  return await $cpFetch<{ articles: ICpArticle[] }>(catalogMSPaths.articles, options);
};
